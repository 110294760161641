import React, { useState, useEffect } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminAddCoupon.css";
import axios from "axios"; // Import axios for making API calls
import { message } from "antd"; // Import axios for making API calls
import DeleteIcon from "@mui/icons-material/Delete";

const AdminAddCoupon = () => {
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);
  // shipping charge
  const [shippingCharge, setShippingCharge] = useState(0);
  const [newShippingCharge, setNewShippingCharge] = useState("");
  // coupons
  const [newCouponCode, setNewCouponCode] = useState({
    name: "",
    discount: "",
  });
  const [data, setData] = useState({});

  const updateShippingCharge = async () => {
    if (isNaN(newShippingCharge)) {
      return message.error("Enter a number only");
    }
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/admin/update-shipping-charge",
        {
          shipping: newShippingCharge,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        fetchShippingCharge();
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating shipping charge:", error);
    }
  };

  const fetchShippingCharge = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-shipping-charge");
      if (res.data.success) {
        setShippingCharge(res.data.data[0].shippingCharge);
        setData(res.data.data[0]);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching shipping charge:", error);
    }
  };

  const handleChange = (e) => {
    setNewCouponCode({ ...newCouponCode, [e.target.name]: e.target.value });
  };

  const handleAddCoupon = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/admin/add-coupon",
        { name: newCouponCode?.name, discount: newCouponCode?.discount },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setNewCouponCode({ name: "", discount: "" });
        fetchShippingCharge();
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteCoupon = async (name) => {
    try {
      const res = await axios.post(
        "/api/admin/delete-coupon",
        { name: name },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        fetchShippingCharge();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchShippingCharge();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Offers/Shipping Charge</h3>
        </div>
        <hr />
        <div className="admin-coupon-container">
          <div className="admin-offer-btn-tabs">
            <button
              onClick={() => setTabs(0)}
              className={`${tabs === 0 && "active"}`}
            >
              Shipping
            </button>
            <button
              onClick={() => setTabs(1)}
              className={`${tabs === 1 && "active"}`}
            >
              Coupon
            </button>
          </div>
          {tabs === 0 && (
            <>
              <b>
                Shipping Charge:{" "}
                {loading ? (
                  <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  shippingCharge
                )}
              </b>
              <hr className="m-0 mb-3" />
              <div className="shipping-charge form-fields">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter shipping charge"
                  value={newShippingCharge}
                  onChange={(e) => setNewShippingCharge(e.target.value)}
                />
                <button
                  className="add-to-cart-btn py-3 m-0"
                  onClick={updateShippingCharge}
                >
                  Update
                </button>
              </div>
              <span>
                <small>Note: For free shipping enter 0 (zero)</small>
              </span>
            </>
          )}
          {tabs === 1 && (
            <>
              <b>Add Coupon</b>
              <hr className="m-0 mb-3" />
              <div className="add-coupon-container form-fields">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter coupon code"
                  name="name"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Discount in rupees"
                  name="discount"
                  onChange={handleChange}
                />
                <button
                  className="add-to-cart-btn py-3 m-0"
                  onClick={handleAddCoupon}
                >
                  {loading ? "Adding.." : "Add"}
                </button>
              </div>
              <div className="coupons-container">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Coupon Name</th>
                      <th>Discount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.coupons?.length === 0 ? (
                      <tr className="text-center">
                        <td colSpan="3">No Coupon Found</td>
                      </tr>
                    ) : (
                      data &&
                      data?.coupons?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item?.name}</td>
                            <td>{item?.discount}</td>
                            <td>
                              <DeleteIcon
                                className="icon"
                                onClick={() => handleDeleteCoupon(item?.name)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAddCoupon;

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DiamondIcon from "@mui/icons-material/Diamond";
import "../Footer/Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/917005581860">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>

        <div className="row">
          <div className="col-12 mb-5">
            <div className="footer-logo">
              <span className="fs-1">
                <DiamondIcon className="me-2 icon" />
                BUSANOFFICIAL
              </span>
            </div>
            <span>
              Welcome to BusanOfficial! Instantly recharge your favorite games
              with secure payments and 24/7 availability. Game on, Anytime!
            </span>
          </div>
          <div className="quick-links col-6 col-sm-6 col-md-4 col-lg-4 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {/* <li>
                <Link to="/about">About us</Link>
              </li> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/track-order">Track Order</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-4">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <h6>Connect here</h6>
            <hr />
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/busan_offical"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/917005581860">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:busanoinam@outlook.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
            <h6 className="mt-4 mb-0">Contact:</h6>
            <span>+91 7005581860</span>
          </div>
          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2024 | BUSAN OFFICIAL |{" "}
              <br className="d-block d-md-none d-lg-none" /> Website Designed &
              Developed by{" "}
              <Link
                className="aashirdigital"
                target="_blank"
                to="https://aashirdigital.com"
              >
                ~aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;

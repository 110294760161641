import React from "react";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LanguageIcon from "@mui/icons-material/Language";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="container-fluid how-it-works">
      <div className="container before-footer">
        <div className="row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-6 text-center mb-2 mb-md-0 mb-lg-0">
            <LocalShippingIcon className="icon" />
            <h6 className="my-3 poppins">
              24/7 Instant <br /> Delivery
            </h6>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-6 text-center mb-2 mb-md-0 mb-lg-0">
            <SyncLockIcon className="icon" />
            <h6 className="my-3 poppins">
              100% Safe and <br /> Legitimate
            </h6>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-6 text-center mb-2 mb-md-0 mb-lg-0">
            <LanguageIcon className="icon" />
            <h6 className="my-3 poppins">
              Easy and secure <br /> payment methods
            </h6>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-6 text-center mb-2 mb-md-0 mb-lg-0">
            <SupportAgentIcon className="icon" />
            <h6 className="my-3 poppins">
              24/7 Instant
              <br /> Support
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;

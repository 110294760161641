import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import { useNavigate, useParams } from "react-router-dom";
import HowItWorks from "../components/Home/HowItWorks";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LockClockIcon from "@mui/icons-material/LockClock";
import VerifiedIcon from "@mui/icons-material/Verified";
import DiamondIcon from "@mui/icons-material/Diamond";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { setUser } from "../redux/features/userSlice";
import getUserData from "../utils/userDataService.js";
import CryptoJS from "crypto-js";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import "./ProductInfo.css";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [userAllow, setUserAllow] = useState(false);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [mode, setMode] = useState("WALLET");
  //!NEW STATE
  const [playerId, setPlayerId] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  //? ========================= SECRET KEY ==============
  const secretKey = "BUSAN@#$123OFFICIAL";
  const decryptPrice = (encryptedPrice) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return encryptedPrice;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedPrice, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  //? ========================= SECRET KEY ==============

  const generateOrderId = () => {
    const numbers = "01234567"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = randomNumbers.join("");
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      // setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.apiName === "smileOne" ? product?.region : "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: product?.apiName === "smileOne" ? idPro : "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
        if (product?.user === res.data.use) {
          setUserAllow(true);
        } else {
          setUserAllow(false);
        }
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getProduct();
  }, [user]);

  //* ================================= ORDER SYSTEM ==========================================
  //* ================================= ORDER SYSTEM ==========================================
  //* ================================= ORDER SYSTEM ==========================================

  function checkPlaceOrder(e) {
    if (product?.api === "no") {
      if (product?.fields === "2") {
        if (userId === "" || zoneId === "") {
          return message.error("Some Fields are missing");
        }
      } else if (product?.fields === "1") {
        if (userId === "") {
          return message.error("Some Fields are missing");
        }
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (product?.gameName === "Clash Of Clans") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (product?.gameName === "Genshin Impact") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else if (product.api === "yes" && product?.apiName === "hopeStore") {
      if (product?.gameName === "Pubg") {
        if (userId === "") {
          return message.error("Enter ID");
        }
      }
      if (
        product?.gameName === "Genshin Impact" ||
        product?.gameName === "Mobile Legends"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "hopeStore") {
        if (mode === "UPI") {
          handleHopeStoreUpiOrder(e);
        } else {
          handleHopeStoreWalletOrder(e);
        }
      } else {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // YOKCASH
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://busanofficial.com/api/yokcash/check-yokcash-upi-order?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post(
        "/api/yokcash/place-yokcash-from-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // HOPESTORE
  async function handleHopeStoreUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://busanofficial.com/api/hopestore/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post(
        "/api/hopestore/create",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleHopeStoreWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/hopestore/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // SMILE
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://busanofficial.com/api/payment/check-api-upi-order?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    const orderObject = {
      client_txn_id: orderId,
      userid: userId.trim(),
      zoneid: zoneId.trim(),
      productid: productId,
      region: product.region,
      customer_email: user?.email,
      customer_mobile: user?.mobile,
      pname: product?.name,
      amount: amount,
      price: selectedPrice,
    };

    setLoading(true);
    const res = await axios.post(
      "/api/payment/place-order-from-wallet",
      orderObject,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (res.data.success) {
      message.success(res.data.message);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(res.data.message);
    }
  };

  // MANUAL
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://busanofficial.com/api/payment/check-manual-upi-order?orderId=${orderId}`,
        txn_note: userId.trim() + "@" + zoneId.trim() + "@" + amount,
      };

      const response = await axios.post(
        "/api/payment/create-manual-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      const res = await axios.post("/api/payment/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        message.success(res.data.message);
        navigate("/orders");
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
      } else {
        message.error(res.data.message);
        setLoading(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  return (
    <Layout>
      <div
        className="p-info-container area"
        style={{
          backgroundImage: `url(/${product?.bannerImage?.replace(/\\/g, "/")})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pro-img">
          <img src={`/${product?.image}`} alt="" />
          <div>
            <h2 className="m-0">{product?.name}</h2>
            <h6 className="m-0 d-none d-md-none d-lg-block">
              {product?.descTwo}
            </h6>
            <div className="features">
              <button>
                <SupportAgentIcon className="icon" />
                24/7 Support
              </button>
              <button>
                <AssuredWorkloadIcon className="icon" />
                Secure Payment
              </button>
              <button>
                <VerifiedUserIcon className="icon" />
                Official Store
              </button>
              <button>
                <WorkspacePremiumIcon className="icon" />
                Quality Assurance
              </button>
              <button>
                <ElectricBoltIcon className="icon" />
                Fast Delivery
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="product-info-container">
        {loading && (
          <div className="order-process">
            <DiamondIcon className="icon" />
            <div class="wrapper">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="shadow"></div>
              <div class="shadow"></div>
              <div class="shadow"></div>
            </div>
          </div>
        )}

        {/* ========================================= LEFT SECTION*/}
        {/* ========================================= LEFT SECTION*/}
        {/* ========================================= LEFT SECTION*/}
        <div className="product-info-img d-none d-lg-block">
          {/* <img src={`/${product?.image}`} alt="" /> */}
          <h3>{product?.name}</h3>
          <div className="info-btns">
            <button>
              <LockClockIcon className="icon me-2" />
              Secure Payments
            </button>
            <button>
              <VerifiedIcon className="icon me-2" />
              Official Distributor
            </button>
          </div>
          <span>{product?.desc}</span>
        </div>
        {/* ========================================= RIGHT SECTION*/}
        {/* ========================================= RIGHT SECTION*/}
        {/* ========================================= RIGHT SECTION*/}
        <div className="product-info-content mt-5 mt-lg-0 mb-2">
          {/* USER INPUTS  */}
          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">Enter IDs</h4>
            {product?.apiName === "smileOne" ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check Player Name
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                {userAllow && playerCheck && (
                  <span className="text-success">Username: {playerCheck}</span>
                )}
                {!userAllow && playerCheck && (
                  <span className="text-danger">Username: {playerCheck}</span>
                )}
              </>
            ) : (product?.apiName === "yokcash" ||
                product?.apiName === "hopeStore") &&
              (product?.gameName === "ML Region Luar" ||
                product?.gameName === "Mobile Legends") ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                <span className="text-success">
                  {playerCheck && "Username: " + playerCheck}
                </span>
              </>
            ) : (product?.apiName === "yokcash" ||
                product?.apiName === "hopeStore") &&
              (product?.gameName === "Genshin Impact" ||
                product?.gameName === "Honkai Star Rail") ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="USER ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <select
                  name="zoneid"
                  className="form-select player-select"
                  onChange={(e) => setZoneId(e.target.value)}
                >
                  <option value="">Select Server</option>
                  <option value="America">America</option>
                  <option value="Asia">Asia</option>
                  <option value="Europe">Europe</option>
                  <option value="TW_HK_MO">TW_HK_MO</option>
                </select>
              </>
            ) : (product?.apiName === "yokcash" ||
                product?.apiName === "hopeStore") &&
              (product?.gameName === "Free Fire" ||
                product?.gameName === "Call Of Duty Mobile" ||
                product?.gameName === "PUBG Mobile" ||
                product?.gameName === "Valorant" ||
                product?.gameName === "Clash Of Clans" ||
                product?.gameName === "League of Legends Wild Rift") ? (
              <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tag}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            ) : product?.fields === "2" ? (
              <>
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tagOne}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
                <input
                  className="player-tag"
                  type="text"
                  name="zoneId"
                  placeholder={`${product?.tagTwo}`}
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
              </>
            ) : (
              <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tagOne}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            )}
          </div>

          {/* ========================================= PRODUCT AMOUNT */}
          {/* ========================================= PRODUCT AMOUNT */}
          {/* ========================================= PRODUCT AMOUNT */}

          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">SELECT PACK</h4>
            <div className="p-amount">
              {product?.cost?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setAmount(item.amount);
                      setPriceAndId(item.amount);
                    }}
                    key={index}
                    className={`amount ${amount === item?.amount && "active"}`}
                  >
                    <span>
                      <div
                        className={`amt-img ${
                          amount === item?.amount && "active"
                        }`}
                      >
                        <div>{item.amount}</div>
                        <img src={item?.pimg} alt="" />
                      </div>
                      <div className="price">
                        ₹
                        {user?.reseller === "yes"
                          ? item?.resPrice
                          : item?.price}
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* ========================================= PAYMENT */}
          {/* ========================================= PAYMENT */}
          {/* ========================================= PAYMENT */}

          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">SELECT PAYMENT</h4>
            <div className="payment">
              <div
                onClick={() => setMode("WALLET")}
                className={`wallet ${mode === "WALLET" && "active"}`}
              >
                <div>
                  <AccountBalanceWalletIcon className="icon" />
                  <span className="ms-2 fs-4">
                    {user && <b>Rs. {balance}</b>}
                  </span>
                </div>
                <h4 className="m-0">Wallet</h4>
              </div>
              <div
                onClick={() => setMode("UPI")}
                className={`upi ${mode === "UPI" && "active"}`}
              >
                <img src={IMAGES.upi} alt="" />
                <h4 className="m-0">UPI</h4>
              </div>
            </div>
          </div>

          {/* ========================================= BUY NOW */}
          {/* ========================================= BUY NOW */}

          <div className="white-bg">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="text-dark m-0 hrline mb-4">BUY!</h4>
              <h4 className="text-dark m-0">Rs. {selectedPrice}</h4>
            </div>
            <span className="d-block m-0 text-end text-dark">
              <small>
                {amount} {" | "}
                <span className="text-danger">Payment Mode - {mode}</span>
              </small>
            </span>
            {user?.block === "yes" || product?.stock === "No" ? (
              <button className="p-check-btn" style={{ opacity: "0.7" }}>
                Out of Stock
              </button>
            ) : product?.apiName === "smileOne" && !userAllow ? (
              <button className="p-check-btn disable">
                This ID is not Allowed
              </button>
            ) : (
              <button onClick={checkPlaceOrder} className="p-check-btn">
                BUY NOW
              </button>
            )}
          </div>
        </div>
      </div>
      <HowItWorks />
    </Layout>
  );
};

export default ProductInfo;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import Link from "antd/es/typography/Link";
import "./NewsAndUpdates.css";

const NewsAndUpdates = () => {
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    async function getBanner() {
      try {
        const res = await axios.get("/api/banner/get-news-banner");
        if (res.data.success) {
          setBanner(res.data.data);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getBanner();
  }, []);

  function handleNavigate() {
    window.open(banner.link, "_blank");
  }

  return (
    <div className="news-and-updates">
      {banner && (
        <>
          <h1>News & Updates</h1>
          <img onClick={handleNavigate} src={banner?.image} alt="news" />
        </>
      )}
    </div>
  );
};

export default NewsAndUpdates;

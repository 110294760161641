import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import "./Register.css";

const Login = () => {
  const clientId =
    "1039933309670-c8rkqblm4h3dtf1o5g9jnvvv93nunjoo.apps.googleusercontent.com";

  const handleSuccess = async (response) => {
    try {
      const res = await axios.post("/api/user/google-login", {
        email: response.profileObj.email,
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        navigate("/user-dasboard");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFailure = (error) => {
    message.error("Failed to Login. Try again later.");
  };

  const navigate = useNavigate();
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.email === "globallog124@gmail.com") {
      try {
        const res = await axios.post("/api/user/admin", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin-dashboard");
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axios.post("/api/user/login", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate(-1);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        message.error("Something went wrong");
      }
    }
  };

  return (
    <Layout>
      <div className="container-fluid hero-container register-container">
        <div className="row text-center">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="register-form form">
              <h2>Sign In!</h2>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.email}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.password}
                  name="password"
                  type="text"
                  className="form-control"
                  placeholder="Password"
                />
              </div>
              <button
                onClick={handleSubmit}
                className="register-btn text-wheite"
              >
                Login
              </button>
              <span className="or-span">OR</span>
              <div className="google-btn-container">
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Login with Google"
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
              <div className="forgot-pass d-flex justify-content-between">
                <h6 className="text-center text-white my-2">
                  New Customer? <Link to="/register">Sign Up</Link>
                </h6>
                <h6 className="text-center text-white my-2">
                  Forgot Password? <Link to="/forgot-password">Click Here</Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;

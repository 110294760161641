import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PercentIcon from "@mui/icons-material/Percent";
import DiamondIcon from "@mui/icons-material/Diamond";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import "./Features.css";

const Features = () => {
  return (
    <div className="features-container">
      <h1>
        <DiamondIcon className="me-2 iconlogo" />
        BUSAN OFFICIAL INDIA
      </h1>
      <h6>
        <b>Why top up games on Busan Official?</b>
      </h6>
      <p>
        Every month, millions of gamers trust Busan Official for an effortless
        purchase experience when buying game credits or vouchers. Your purchases
        are instantly added to your game account. Top up Call of Duty, Mobile
        Legends, BGMI and many more now!
      </p>
      <div className="row mt-5">
        <div className="feature col-12 col-sm-12 col-md-6 col-lg-6">
          <SpeedIcon className="icon" />
          <div>
            <h5>Easy and Fast</h5>
            <p>
              Making a purchase on Busan Official is quick and takes just a few
              seconds.
            </p>
          </div>
        </div>
        <div className="feature col-12 col-sm-12 col-md-6 col-lg-6">
          <AccountBalanceWalletIcon className="icon" />
          <div>
            <h5>Hassle-Free Payment Options</h5>
            <p>
              For your convenience, we have collaborated with the leading
              providers in India.
            </p>
          </div>
        </div>
        <div className="feature col-12 col-sm-12 col-md-6 col-lg-6">
          <PercentIcon className="icon" />
          <div>
            <h5>Exclusive Deals and Offers</h5>
            <p>
              Stay tuned for the best deals on your favorite games with Busan
              Official promotions.
            </p>
          </div>
        </div>
        <div className="feature col-12 col-sm-12 col-md-6 col-lg-6">
          <DiamondIcon className="icon" />
          <div>
            <h5>Fast and Immediate Delivery</h5>
            <p>
              When you top-up on Busan Official, your purchase is instantly
              delivered to your game account as soon as your payment is
              processed.
            </p>
          </div>
        </div>
        <div className="feature col-12 col-sm-12 col-md-6 col-lg-6">
          <SupportAgentIcon className="icon" />
          <div>
            <h5>Dedicated Customer Support</h5>
            <p>
              Our friendly customer support team is available around the clock
              to assist you. Get in touch with us today!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
